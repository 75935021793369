@charset "UTF-8";

@import "./variables";
@import "./layout";
@import "./functions";

//---------------------------------------------------------------
// Component
//---------------------------------------------------------------

.l-renewal-content {

  .c-menu-list {

    .c-accordion-body li {
      a {
        display: block;
        padding-top: 14px;
        font-size: 14px;
        line-height: 1.6;
      }

      &:nth-child(1) {
        a {
          padding-top: 20px;
        }
      }

      &:nth-child(n+2) {
        a {
          padding-top: 14px;
        }
      }

    }

    &.c-menu-header {

      @media (max-width: $media-small) {

        p {
          border-bottom: $border-set-dark-gray;
          padding: 13px 0;
        }
        a {
          border-bottom: $border-set-dark-gray;
          position: relative;
          padding: 13px 0 !important;
          font-size: 13px !important;

          &:not(.c-action-menu):after {
            content: getIconUrl("icon_spnav_link.svg");
            position: absolute;
            right: 10px;
            color: $text-gray;
          }

        }
      }

    }

    &:not(.c-menu-header) {

      p {
        border-bottom: $border-set-dark-gray;
        padding: 13px 0 !important;
      }

      a {
        border-bottom: $border-set-dark-gray;
        position: relative;
        padding: 13px 0 !important;
        font-size: 13px !important;

        &:after {
          content: getIconUrl("icon_spnav_link.svg");
          position: absolute;
          right: 10px;
          color: $text-gray;
        }

      }
    }

    .menu-count {
      color: $text-primary;
      background-color: #FEF4EB;
      display: inline-block;
      padding: 2px 5px;
      border-radius: 5px;
      margin-left: 10px;
    }
  }

  .c-accordion {
    @extend .c-text-primary;
    @extend .c-bold;
    position: relative;

    &:hover {
      @media (min-width: $media-small) {
        cursor: pointer;
        opacity: 0.7;
      }
    }

    &:after {
      content: "＋";
      font-weight: normal;
      position: absolute;
      right: 10px;
      color: $text-gray-accordion;
    }

    &.open:after {
      font-weight: normal;
      content: "－";
    }
  }

  .c-accordion-body {
    max-height: 0;
    transition: max-height 0.5s;
    overflow: hidden;

    &.show {
      display: block;
      height: auto;
      max-height: 100vh;
    }
  }

  .c-section {
    //padding: 18px 20px;
    //width: 100%;
    //
    //@media (max-width: $media-small) {
    //  padding: 16px 16px;
    //}
    //
    //&:last-child {
    //  padding-bottom: 48px;
    //}
    //
    //&-middle {
    //  padding: 24px 20px 0;
    //  width: 100%;
    //
    //  @media (max-width: $media-small) {
    //    padding: 16px 16px;
    //  }
    //
    //  &:last-child {
    //    padding-bottom: 48px;
    //  }
    //}
  }

  .c-bold {
    font-weight: $font-weight-bold;
  }

  .c-bg- {
    &userinfo {
      background-color: $bg-userinfo;
    }

    &yellow {
      background-color: $bg-userinfo;
    }

    &gray {
      background-color: $bg-base-gray;
    }

    &content {
      background-color: $bg-base-white;
    }

    &primary {
      background-color: $bg-primary;
      color: $text-white;
    }

    &tiktok {
      background-color: #E5F8FA;
    }

    &instagram {
      background-color: #FAF0FC;
    }

    &twitter {
      background-color: #EDF8FF;
    }

    &blog {
      background-color: #F5EEEE;
    }

    &sub-light2{
      background-color: $bg-sub-light2;
    }

    &bestpost{
      background-color: $bg-best-post;
    }

    &bestpost-past{
      background-color: $bg-best-post-past;
    }
  }

  .c-text- {
    &diamond {
      color: $text-diamond;
    }

    &primary {
      color: $text-primary;
    }

    &gray {
      color: $text-gray;
    }

    &white {
      color: $text-white;
    }

    &page-title {
      @extend .c-text-primary;
      @extend .c-bold;
      font-size: 24px;
    }

    &section-title {
      @extend .c-text-primary;
      @extend .c-bold;
      font-size: 24px;
      border-bottom: 6px solid $border-color-primary;
      margin-bottom: 40px;
      margin-top: 24px;
      padding-bottom: 16px;
      text-align: center;
    }

    &limit {
      @extend .c-text-primary;
      @extend .c-bold;
      border-radius: 10px;
      padding: 10px;
      display: inline-block;
      font-size: 18px;
      background-color: $bg-emp;
    }

    &large {
      font-size: $font-size-l;
    }

    &xxx {
      font-size: $font-size-xxx;
    }

    &emp {
      @extend .c-text-xxx;
      @extend .c-bold;
    }

    &header {
      @extend .c-text-primary;
      @extend .c-bold;
      font-size: $font-size-l;
    }

    &cautionary-note {
      font-size: $font-size-s;
      color: $text-cautionary-note;
    }

    &point {
      &:after {
        content: "pt";
      }
    }

    &emphasis {
      display: inline-block;
      padding: 5px 10px;
      text-align: center;

      background-color: $bg-sub-light;
      color: $text-primary;
      border-radius: 10px;
      font-size: 18px;
      font-weight: $font-weight-bold;
    }

    &attention-emp {
      color: $text-emp;
      @extend .c-bold;
    }

    &normal {
      color: $text-base;
    }

    &instagram {
      color: #B479C0;
    }

    &tiktok {
      color: #56AFB6;
    }

    &twitter {
      color: #4396CB;
    }

    &blog {
      color: #A18E8E;
    }
  }

  .c-round- {
    &img {
      border-radius: 50%;
    }
  }

  .c-done-img {
    position: relative;

    &:after {
      position: absolute;
      top: 0;
      left: 0;

      background-color: rgba(187, 187, 187, 0.75);
      border-radius: 50%;

      width: 100%;
      height: 100%;

      content: "済";
      color: white;
      font-size: 26px;
      font-weight: $font-weight-bold;
      text-align: center;

      padding-top: calc(50% - 17px);

    }
  }

  .c-border-img {
    border-radius: 50%;
    border: $bg-primary 5px solid;
  }

  .c-icon:before {
    content: "";
    display: inline-block;
    background-position: center;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
  }

  .c-link-icon:before {
    @extend .c-icon:before;
    background-color: $bg-icon;
    border-radius: 50%;
    margin-right: 10px;
    transform: translateY(3px);
  }

  .c-link-button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 32px;
    height: 32px;
    border-radius: 5px;
    color: $text-primary;
    font-size: 16px;

    &.now {
      background-color: $bg-primary;
      color: $text-white;
      &:hover {
        color: $text-white;
      }
    }
  }


  .c-emp-point {
    @extend .c-text-primary;
    @extend .c-bold;

    background-color: $bg-emp;
    border-radius: 0;
    padding: 5px;
  }

  .c-point {
    &:after {
      content: "pt";
    }
  }

  .c-emp {
    &-place {
      background-color: $bg-bright-orange;
      color: $text-white;
      border-radius: 45px;
      font-size: $font-size-xl;
      letter-spacing: 0.025em;
    }
  }

  .c-notification-count:after {
    position: absolute;
    content: attr(data-length);
    color: $text-white;
    background-color: $bg-primary;
    border-radius: 50%;
    font-size: $font-size-xs;
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1px;
    font-weight: 700;
  }

  .c-button-wrapper {
    margin: 40px auto;
    text-align: center;

    &.isMore {
      margin: 40px auto 56px;
    }
  }
  @media (max-width: $media-small) {
    .c-button-wrapper {
      margin: 24px auto;
      text-align: center;

      &.isMore {
        margin: 24px auto 40px;
      }
    }
  }

  .c-button {
    @extend .l-center;

    position: relative;
    background-color: $bg-button;
    border-radius: 10px;
    border: $border-color-dark-gray 1px solid;
    color: $text-gray;
    font-size: $font-size-xs;
    padding: 8px 16px;
    box-shadow: $shadow-default;

    &.black {
      background-color: $bg-black;
      color: $text-white;
    }

    &.gray {
      background-color: $bg-gray1;
      color: $text-base;
    }

    &-disabled {
      background-color: $bg-button-disabled;
      color: $text-white;
      box-shadow: none;
    }

    &.c-mail {
      &:before {
        @extend .c-icon:before;
        position: absolute;
        left: 20px;
        top: 8px;
        background-image: getIconUrl("icon_floatmenu_message.svg");

      }

      .c-notification-count:after {
        top: 2px;
        left: 34px;
      }
    }

    &.round {
      border-radius: 45px;
    }

    &.sns {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 220px;
      @media (max-width: $media-small) {
        width: 270px;
      }
    }

    &-form {
      font-size: 16px;
      font-weight: $font-weight-bold;
      background-color: $bg-primary;
      color: $text-white;
      border-radius: 27px;
      width: 270px;
      display: inline-block;
      padding: 10px 40px;
      border: none;
      box-shadow: $shadow-default;
      text-align: center;
      line-height: 100%;

      @media (max-width: $media-small) {
        margin: 0 auto;
      }

      &:hover, &:hover:not(.color-disable) {
        cursor: pointer;
        color: $text-white;
      }

      &:disabled,
      &.cancel {
        background-color: $bg-button-disabled;
      }

      &:disabled {
        &:hover {
          cursor: initial;
        }
      }

      &.black {
        background-color: $bg-black;
        color: $text-white;
      }

      &.login {
        background-color: $bg-base-white;
        color: $text-primary;
        height: 44px;
        line-height: 44px;
        padding: 0 10px;
      }

      &.register {
        height: 44px;
        line-height: 44px;
        padding: 0 10px;
      }

      &.border {
        border: $border-set-base;
      }
    }

    &.zip {
      padding: 12px 18px;
      font-size: 14px;
      background-color: $bg-primary;
      color: $text-white;

      &:hover {
        cursor: pointer;
      }

      &:disabled {
        background-color: $bg-button-disabled;

        &:hover {
          cursor: initial;
        }
      }
    }

    &.edit {
      border-radius: 40px;
      width: 60px;
      font-size: 14px;
      color: $text-white;
      background-color: $bg-button-disabled;
      padding: 8px 2px;
      font-weight: 700;
      border: none;
    }

    &.TikTok {
      background-color: $bg-sns-tiktok;

      &.icon {
        &:before {
          @extend .c-icon:before;
          position: absolute;
          left: 20px;
          top: 8px;
          background-image: getIconUrl("icon_tiktok.svg");
        }
      }
    }

    &.Instagram {
      background-color: $bg-sns-instagram;
    }

    &.X,
    &.x {
      background-color: $bg-sns-x;

      &.icon {
        &:before {
          @extend .c-icon:before;
          position: absolute;
          left: 20px;
          top: 8px;
          background-image: getIconUrl("icon_x.svg");
        }
      }
    }

    &.LINE {
      background-color: $bg-sns-LINE;
    }

    &.blog {
      background-color: $bg-sns-Ameba;

      &.icon {
        &:before {
          @extend .c-icon:before;
          position: absolute;
          left: 20px;
          top: 8px;
          background-image: getIconUrl("icon_ameba.svg");
        }
      }
    }

    &.primary {
      background-color: $bg-primary;
      color: $text-white;
    }

    &.narrow {
      padding-top: 2px;
      padding-bottom: 2px;
      border-radius: 4px;
    }

    &:disabled {
      background-color: $bg-disabled;
      color: $text-white;
      box-shadow: none;
    }

    &.connected {
      background-color: $bg-sns-disconnect;
      box-shadow: none;
    }

    &.no-shadow {
      box-shadow: none;
    }

  }

  .c-event-card {
    border-bottom: $border-set-primary;
    padding: 16px 0;

    &.first {
      border-top: none;
    }

    .item-img {
      position: relative;
    }

    .event-status {
      border: 1px solid $border-color-primary-bold;
      border-radius: 4px;
      padding: 8px 12px;

      & > .column {
        padding-top: 0;
        padding-bottom: 2px;
      }

      &.disabled {
        border-color: $bg-disabled;
        padding: 5px 12px;

        & * {
          color: $text-disabled;
        }
      }

      &.winOrLose {
        padding: 4px 12px;
      }
    }
  }

  .c-event-card-item {
    width: 100%;

    @media screen and (min-width: $media-small) {
      width: calc(25% - 8px);
    }
  }

  .c-bottom-label {
    // 共通スタイルを mixin で定義
    @mixin label-style($bg-color) {
      content: attr(data-content); // 内容を HTML 属性から取得
      color: $text-white;
      text-align: center;
      font-size: 12px;
      font-weight: $font-weight-bold;
      line-height: 100%;
      background-color: $bg-color;
      border-radius: 40px;
      width: 60px;
      padding: 4px 10px;
      display: inline-block;
      position: absolute;
      //left: 10px;
      //top: 90px;
      left: 50%;
      top: 100%;
      transform: translateX(-50%) translateY(-70%);

      @media (min-width: $media-small) {
        left: 50%;
        top: 100%;
        transform: translateX(-50%) translateY(-70%);
      }
    }

    @mixin label-style-lg() {
      width: 90px;
      font-size: 14px;
    }

    &-new:after {
      @include label-style($bg-primary); // "NEW"用の背景色
      content: "NEW"; // デフォルトの内容
    }

    &-new-lg-tablet:after {
      @extend .c-bottom-label-new;
      @media (min-width: $media-small) {
        @include label-style-lg();
      }
    }

    &-entered:after {
      @include label-style($bg-gray3); // "応募済"用の背景色
      content: "応募済"; // デフォルトの内容
    }

    &-entered-lg-tablet:after {
      @extend .c-bottom-label-entered;
      @media (min-width: $media-small) {
        @include label-style-lg();
      }
    }
  }

  .c-input-secret {
    &.primary {
      background-color: $bg-primary;
      color: $text-white;
    }

    &.narrow {
      padding-top: 2px;
      padding-bottom: 2px;
      border-radius: 4px;
    }

    &:disabled {
      background-color: $bg-disabled;
      color: $text-white;
      box-shadow: none;
    }

    &.connected {
      background-color: $bg-sns-disconnect;
      box-shadow: none;
    }

    &.no-shadow {
      box-shadow: none;
    }

  }


  .c-event-card {
    border-bottom: $border-set-primary;
  }

  .item-img{
    position: relative;

    input {
      width: 100%;
    }

    i.secret {
      position: absolute;
      content: getIconUrl("icon_input_not_view.svg");
      top: 0;
      right: 12px;
      width: 24px;
      transform: translateY(24px) translateY(-6px);

      &:hover {
        cursor: pointer;
      }
    }

    &.view i.secret {
      content: getIconUrl("icon_input_view.svg");
    }
  }

  .c-input-authentication {
    width: 40px;
    font-size: 15px;
    font-weight: $font-weight-bold;
    text-align: center;
    margin-top: 0;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
      -moz-appearance: textfield;
    }
  }

  a.c-decoration-link {
    text-decoration: 1px underline #555;
  }

  .c-form-part {
    padding: 20px 0;
    min-height: 102px;
    border-bottom: $border-set-primary;

    &.first {
      border-top: $border-set-primary;
    }

    &.no-border {
      border-bottom: none;
    }

  }

  .c-form-title {
    color: $text-primary;
    font-weight: $font-weight-bold;
    font-size: 16px;
    padding: 8px 0;
    margin-bottom: 24px;

    @media (min-width: $media-small) {
      font-size: 18px;
      padding: 18px 0 8px;
      margin-bottom: 48px;
    }
  }

  .c-error-text {
    display: none;
    color: $text-error;
    font-weight: $font-weight-bold;
    font-size: 12px;
  }

  .c-error-text-block {
    display: block;
    color: $text-error;
    font-weight: $font-weight-bold;
    font-size: 12px;
    white-space: pre-wrap;
  }

  input.error,
  select.error {
    background-color: $bg-error;

    &.null-error ~ .c-error-text.null-error {
      display: block;
    }

    &.num-error ~ .c-error-text.num-error {
      display: block;
    }

    &.pass-length-error ~ .c-error-text.pass-length-error {
      display: block;
    }

    &.pass-chars-error ~ .c-error-text.pass-chars-error {
      display: block;
    }

    &.pass-num-error ~ .c-error-text.pass-num-error {
      display: block;
    }

    &.pass-char-error ~ .c-error-text.pass-char-error {
      display: block;
    }

    &.pass-confirm-error ~ .c-error-text.pass-confirm-error {
      display: block;
    }

    &.mail-error ~ .c-error-text.mail-error {
      display: block;
    }
  }

  .c-form-error {
    background-color: $bg-error;
    display: none;
    padding: 16px 12px;

    & > p {
      display: none;

      & * {
        color: $text-error;
      }
    }

    .title {
      @extend .c-bold;
      font-size: 14px;
      margin-top: 8px;
      margin-bottom: 8px;
    }

    &.error {
      display: block;

      &.null-error .null-error {
        display: block;

        .detail:after {
          content: "が入力されていません。"
        }
      }

      &.input-error .input-error {
        display: block;

        .detail:after {
          content: "の入力にエラーがあります。"
        }
      }
    }

    &-item {
      input, select {
        background-color: $bg-error;
      }
    }

  }

  .c-form {
    &-radio {
      font-weight: $font-weight-default;
      font-size: 14px;

      input[type=radio] {
        margin-right: 10px;
      }

      &.column {
        margin-right: 0;
        @media (min-width: $media-small) {
          padding: 2px 8px;
        }
      }

      @media (max-width: $media-small) {
        & {
          display: block;
          border: 1px solid #ccc;
          padding: 12px 10px;
          border-radius: 10px;
          margin: 5px auto;

          &.other-text.checked {
            background-color: #FAE8D4;
          }
        }
      }
    }

    &-check {
      display: inline-block;
      font-weight: $font-weight-default;
      font-size: 14px;
      margin-right: 32px;
      margin-bottom: 16px;
      @media (max-width: $media-small) {
        margin-right: 20px;
        margin-bottom: 20px;
      }

      input[type=checkbox] {
        display: none;
      }

      .checkbox {
        display: inline-block;
        position: relative;
        width: 18px;
        height: 18px;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-right: 8px;
      }

      input[type=checkbox]:checked {
        & ~ .checkbox:before {
          position: absolute;
          content: getIconUrl("icon_checked.svg");
          top: -1px;
          left: 1px;
        }
      }
      input[type=checkbox]:disabled {
        & ~ .checkbox {
          opacity: 0.7;
          cursor: not-allowed;
        }
        & ~ .text {
          opacity: 0.6;
          cursor: not-allowed;
        }
      }
    }

    &-select {
      display: flex;
      flex-direction: column;

      &-top-label {
        height: 20px;
        text-align: left;
        font-size: 12px;
        color: $text-disabled;
      }

      &-content {
        flex: 1 1 auto;
        margin: 0;
      }
    }
  }

  .c-attention {
    color: $text-attention-note;
    font-size: 12px;

    &.background-on {
      background-color: $bg-sub-light2;
    }

    & * {
      color: $text-attention-note;
    }

    .title {
      @extend .c-bold;
      font-size: 18px;
      padding-bottom: 18px;
      border-bottom: $border-set-primary-3p;
      vertical-align: center;

      &.border-none,
      &.border-none * {
        border-bottom: none;
      }

      * {
        padding-bottom: 18px;
      }
    }

    .attention {
      text-decoration: initial;
      padding-bottom: 16px;
      border-bottom: $border-set-primary-3p;
    }

    ul > li {
      list-style: none;
      padding-top: 8px;

      &:before {
        content: "・";
        padding-right: 5px;
      }
    }
    // 1行目のpadding-topを削除
    ul > li:first-child {
      padding-top: 0;
    }

    .normal-text,
    .normal-text * {
      color: $text-base;

      li {
        color: $text-base;
      }
    }

    .color {
      color: $text-primary;
    }
  }

  li.c-li-dot,
  .c-li-dot > li {
    list-style: none;

    &:before {
      content: "・";
    }
  }

  li.c-li-black-circle,
  .c-li-black-circle > li {
    list-style: none;

    &:before {
      content: "●";
    }
  }

  hr.c-border {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: $border-set-primary;
  }

  .c-border {
    border-bottom: $border-set-primary;

    &-bottom-title {
      border-bottom: $border-color-primary 6px solid;
    }

    &-bold {
      border-bottom: $border-color-primary 3px solid;
    }

    &-normal {
      border-bottom: $border-color-primary 2px solid;
    }

    &-light {
      border-bottom: $border-color-primary 1px solid;
    }

    &.c-border-first {
      border-top: $border-set-primary;
    }

    &.middle {
      border-spacing: 3px;
    }

    &-all {
      border: $border-set-primary;
    }
  }

  .c-trouble {
    text-align: center;
    background-color: $bg-trouble;
    padding: 24px 0;

    .title {
      font-size: $font-size-default;
    }

    .c-button {
      font-size: $font-size-default;
      margin-top: 8px;
    }
  }

  .c-explanation {
    margin-top: 32px;
    font-size: 14px;

    .title {
      @extend .c-bold;
      font-size: 14px;
      margin-bottom: 8px;
    }
  }

  .c-card {
    border-radius: 12px;
    background-color: $bg-base-white;
    box-shadow: $shadow-thin;
    padding: 24px 20px;
    @media (max-width: $media-small) {
      padding: 16px 12px;
    }
  }

  .c-tab {
    border-bottom: $border-set-primary-6p;

    &.selected {
      border-bottom-color: $bg-primary;
    }

    &:not(.selected):hover {
      cursor: pointer;
      opacity: 0.7;
    }


    &-detail {
      display: none;

      &.show {
        display: block;
      }
    }
  }

  .c-text-section {
    margin-top: 16px;
    margin-bottom: 32px;

    .title {
      color: $text-primary;
      @extend .c-bold;
      font-size: 18px;
      padding-bottom: 10px;
      border-bottom: $border-set-primary;
    }
  }

  .c-label {

    &-sns {
      @extend .l-inline-block;
      width: 150px;
      padding: 8px 0;
      text-align: center;
      border: $border-set-gray;
      border-radius: 10px;
      font-weight: $font-weight-bold;
      margin-right: 5px;
      vertical-align: center;

      i {
        margin-right: 5px;
      }

      @media (max-width: $media-small) {
        width: 82px;
      }

    }
  }

  ol.c-greater-less-symbol {
    li {
      font-size: 10px;
      list-style: none;
      counter-reset: count 1;

      &:before {
        content: "<" counter(count) ">";
        font-size: 10px;
      }
    }
  }

  ol {
    &.c-li-on {
      counter-reset: count 0;

      li {
        list-style: none;
        text-indent: -1em;
        padding-left: 1em;

        &:before {
          content: counter(count) ". ";
          counter-increment: count 1;
        }
      }
    }

    &.c-li-brackets {
      counter-reset: count 0;

      li {
        list-style: none;
        text-indent: -1.5em;
        padding-left: 1.5em;

        &:before {
          content: "(" counter(count) ") ";
          counter-increment: count 1;
        }
      }
    }
  }

  .c-img-box {
    background-color: $bg-gray2;
    width: 300px;
    height: 300px;
    overflow: hidden;

    & > img {
      width: 100%;
      height: 100%;
    }
  }

  .c-ai-check {
    background-color: $bg-trouble;
    border-radius: 10px;
    padding: 0 10px;

    @media (min-width: $media-small) {
      padding: 24px 0;
    }

    .check-text {
      background-color: $bg-primary;
      color: $text-white;
      font-weight: $font-weight-bold;
      padding: 8px 12px;

      @media (min-width: $media-small) {
        width: 86px;
        height: 86px;
        text-align: center;
        border-radius: 50%;
        padding: 13px 0;
      }
    }
  }

  .c-slider {
    display: inline-block;
    width: 32px;
    height: 32px;
    padding: 9px 10px;
    background-color: $bg-gray2;
    border-radius: 50%;

    &:hover {
      cursor: pointer;
    }

    i {
      width: 10px;
    }
  }

  .c-carousel {
    position: relative;

    .c-left-arrow,
    .c-right-arrow {
      position: absolute;
      width: 38px;
      height: 38px;
      transform: translateY(-50%);

      &.c-left-arrow {
        left: 0;
        transform: translateX(-50%);
      }

      &.c-right-arrow {
        right: 0;
        transform: translateX(50%);
      }
    }
  }

  .c-hover-opacity:hover {
    opacity: 0.8;
    cursor: pointer;
  }

  .post-target-sites {
    display: flex;
    gap: 8px;
  }

} // .l-renewal-content

.c-decoration{
  // ※を先頭に付ける
  &-asterisk-ja{
    &-before{
      position: relative;
      padding-left: 13px;

      &:before{
        position: absolute;
        content:"※";
        font-size: 8px;
        top: 5px;
        left: 0;
      }
    }
  }

  &-description-point{
    position: relative;
    color: $text-primary;
    font-size: 20px;
    padding: 3px 0;
    font-weight: $font-weight-bold;
    width: 39px;
    height: 36px;

    span{
      display: inline-block;
      color: $text-primary;
      font-size: 30px;
      position: absolute;
      font-weight: $font-weight-bold;
      z-index: 2;
      top: 0.8em;
      left: 20px;
      transform: translateX(-50%) translateY(-50%);

      &:before{
        content: "POINT";
        color: $border-color-primary;
        position: absolute;
        font-size: 12px;
        top: -2px;
        left: -12px;
        transform: rotate(-15.52deg);
        display: inline-block;
        z-index: -5;
      }
    }
  }
}

.c-best-card {
  width: 213px;
  background-color: $bg-base-white;
  box-shadow: $shadow-default;
  border: $border-set-base;
  border-radius: 12px;
  padding: 8px 5px;
  margin: 0 5.125px 20px;

  &.tiktok {
    .c-best-img {
      height: 352px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &:not(.tiktok) {
    .c-best-img {
      height: 198px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

}