@charset "UTF-8";

@import "../common/variables";
@import "../common/object";
@import "../common/functions";

//---------------------------------------------------------------
// ヘッダー全体
//---------------------------------------------------------------
.l-renewal-content {

  #header {
    background-color: $bg-base-header;
    height: 72px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 12px 40px;
    z-index: 100;
    box-shadow: 0 5px 10px rgba(0,0,0,0.05);
    display: flex;
    align-items: center;

    @media screen and (max-width: $media-small) {
      height: 64px;
      padding: 5px 16px;
    }

    #header-logo {
      width: 95px;

      @media screen and (max-width: $media-small) {
        width: 79px;
      }
    }

    #header-menu {
      position: absolute;
      right: 40px;
      display: flex;
      align-items: center;

      @media screen and (max-width: $media-small) {
        right: 16px;
      }

      a {
        transition: all 0.2s cubic-bezier(0.33, 1, 0.68, 1);

        &:hover {
          color: $text-primary;
        }

        &.c-button-form {
          box-shadow: none;
          font-size: 14px;
          &:hover {
            color: #fff;
          }
        }
        &.c-button-form.login {
          box-shadow: none;
          &:hover {
            color: $text-primary;
          }
        }
      }

      #navs {
        white-space: nowrap;
        padding-top: 3px;

        & > div {
          gap: 0 10px;
        }

        .nav {
          font-size: $font-size-l;
          padding: 4px;
          display: flex;

          &.event:before {
            background-image: getIconUrl("icon_nav_event.svg");
            @extend .c-link-icon:before;
          }

          &.blog:before {
            background-image: getIconUrl("icon_nav_blog.svg");
            @extend .c-link-icon:before;
          }

          &.help:before {
            background-image: getIconUrl("icon_nav_help.svg");
            @extend .c-link-icon:before;
          }
        }
      }

      #user-menu {
        position: relative;
        box-sizing: border-box;

        @media screen and (min-width: $media-small) {
          & {
            border: $border-set-dark-gray;
            border-radius: $border-radius-round;
            padding: 5px 30px 5px 5px;
            width: 180px;
            margin-left: 32px;

            &:hover {
              cursor: pointer;
            }

            &:after {
              content: "";
              position: absolute;
              width: 8px;
              height: 5px;
              top: 22px;
              right: 17px;
              background: getIconUrl("icon_pulldown.svg") $icon-setup-center;
            }
          }
        }

        .user- {
          &icon {
            width: 36px;
            height: 36px;

            @media screen and (max-width: $media-small) {
              width: 40px;
              height: 40px;
            }
          }

          &name {
            padding-left: 8px;
          }
        }
      }

      //---------------------------------------------------------------
      // ハンバーガーメニュー関連
      //---------------------------------------------------------------
      #hamburger-menu {
        display: block;
        width: 32px;
        height: 32px;
        //margin-top: 15px;
        margin-left: 6px;
        padding: 6px;

        &:hover {
          cursor: pointer;
        }

        span {
          display: block;
          width: 20px;
          height: 2px;
          background: #888;
          transition: all 250ms cubic-bezier(0.645, 0.045, 0.355, 1);
          margin: 0 auto;

          &:nth-child(n+2) {
            margin: 6px auto 0;
          }
        }

        &.clicked {
          span {
            &:nth-child(1) {
              transform: translate(0px, 8px) rotate(45deg);
            }

            &:nth-child(2) {
              opacity: 0;
            }

            &:nth-child(3) {
              transform: translate(0px, -8px) rotate(-45deg);
            }
          }
        }
      }
    }

  }


  #h-menu {
    position: fixed;
    z-index: 101;
    background-color: $bg-base-header;
    border: $border-set-base;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;

    @media (max-width: $media-small) {
      width: 100vw;
      height: calc(100vh - 64px);
      overflow-y: auto;
      margin-top: 64px;
      border-radius: 0;
      top: 0;
      left: 0;
    }
    @media (min-width: $media-small) {
      width: 660px;
      margin-top: 72px;
      top: -5px;
      right: 44px;
    }

    #h-menu-user {
      background-color: $bg-base-gray;
      border-bottom: 1px solid $border-color-gray2;
    }

    .user-name {
      width: fit-content;
      overflow: hidden;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      max-height: 46px;
    }

    .c-menu-list {
      padding: 10px;

      @media (min-width: $media-small) {

        .c-accordion {
          &:hover {
            cursor: initial;
            opacity: initial;
          }

          &:after {
            content: "";
          }
        }

        .c-accordion-body {
          display: block !important;
          height: auto !important;
          max-height: 999vh !important;
        }
      }
    }

    .header-menu-button-wrapper {
      width: 116px;
      @media (max-width: $media-small) {
        width: calc(33.33% - 4px);
      }

      a {
        width: 100%;

        .header-menu-button {
          position: relative;
          width: 100%;
          height: 50px;
          background-color: $bg-base-white;
          border-radius: 10px;
          border: $border-set-dark-gray;
          line-height: 1.25;
          font-size: $font-size-m;
          letter-spacing: 0;

          display: flex;
          align-items: center;
          justify-content: center;

          .c-header-menu-button-icon {
            position: relative;
            margin-right: 12px;

            .c-notification-count {
              position: absolute;

              &.present {
                top: -5px;
                left: 13px;
              }

              &.event {
                top: -7px;
                left: 10px;
              }

              &.message {
                top: -10px;
                left: 11px;
              }
            }
          }

          //i {
          //  margin-right: 12px;
          //}

          &.l-columns-mobile {
            display: flex;
          }
        }
      }
    }

  }

  //#header{

  //
  //  .header-menus{
  //    position: absolute;
  //    top: 0;
  //    display: flex;
  //
  //    // スマホサイズの場合はメニューとして表示する
  //    @media (max-width: $media-sm-max){
  //      &{
  //        right: 15px;
  //      }
  //
  //      .navs{
  //        display: none;
  //      }
  //    }
  //
  //    //PCの時だけナビゲーションを標準出力する
  //    @media (min-width: $media-md){
  //      &{
  //        right: 36px;
  //      }
  //
  //      .navs {
  //        padding-top: 24px;
  //
  //        li {
  //          position: relative;
  //          display: inline-block;
  //          font-size: 16px;
  //          padding-left: 28px;
  //          letter-spacing: 0;
  //
  //          &:nth-child(n+2) {
  //            margin-left: 24px;
  //          }
  //
  //          &:before,
  //          &:after {
  //            position: absolute;
  //            content: "";
  //            top: 2px;
  //            left: 0;
  //            width: 20px;
  //            height: 20px;
  //          }
  //
  //          &:before {
  //            background: #FEF4EB;
  //            border-radius: 50%;
  //          }
  //
  //          &.nav-event {
  //            &:after {
  //              background: $icon-nav-event $icon-setup-center;
  //            }
  //          }
  //
  //          &.nav-about {
  //            &:after {
  //              background: $icon-nav-about $icon-setup-center;
  //            }
  //          }
  //
  //          &.nav-blog {
  //            &:after {
  //              background: $icon-nav-blog $icon-setup-center;
  //            }
  //          }
  //
  //          &.nav-help {
  //            &:after {
  //              background: $icon-nav-help $icon-setup-center;
  //            }
  //          }
  //        }
  //      }
  //    }
  //  }
  //
  //}


  //---------------------------------------------------------------
  // ヘッダーおよび左メニューのユーザーアイコン
  //---------------------------------------------------------------
  //.user {
  //
  //  &-info{
  //  }
  //
  //  &-icon{
  //    border-radius: 50%;
  //    width: 40px;
  //    margin-top: 10px;
  //  }
  //
  //  &-name{
  //    display: none;
  //  }
  //
  //  @media (min-width: $media-md) {
  //    &-menu {
  //      position: relative;
  //      display: block;
  //      width: 180px;
  //      height: 48px;
  //      align-items: center;
  //      border: 1px solid #ddd;
  //      border-radius: 50px;
  //      padding: 4px;
  //      margin-top: 12px;
  //      margin-left: 32px;
  //
  //      &:after{
  //        content: "";
  //        position: absolute;
  //        width: 8px;
  //        height: 5px;
  //        top: 20px;
  //        right: 17px;
  //        background: $icon-pulldown $icon-setup-center;
  //      }
  //
  //      &:hover{
  //        background-color: $hover-color-bg-gray;
  //        cursor: pointer;
  //      }
  //    }
  //
  //    &-icon{
  //      margin-top: 0;
  //      width: 38px;
  //    }
  //
  //    &-name{
  //      display: initial;
  //      font-size: 14px;
  //      letter-spacing: 0;
  //      color: $base-color-text-link;
  //    }
  //
  //  }
  //}


  //---------------------------------------------------------------
  // メニュー表示
  //---------------------------------------------------------------

  //.menu{
  //
  //  &-header{
  //    background-color: $base-color-bg-contents;
  //    padding: 20px 5px;
  //    position:relative;
  //
  //  }
  //
  //  &-category,
  //  &-detail .col{
  //    padding: 5px 0;
  //    border-top: 1px solid $base-color-border;
  //    &:hover{
  //      cursor: pointer;
  //      opacity: 0.9;
  //    }
  //  }
  //
  //  &-category{
  //    position: relative;
  //
  //    span {
  //      color: $base-color-text-ripre;
  //    }
  //
  //    &:after{
  //      content: "＋";
  //      position: absolute;
  //      color: $base-color-text-link;
  //      right: 5px;
  //      top: 5px;
  //    }
  //
  //    &[aria-expanded = true]:after{
  //      content: "－";
  //    }
  //
  //  }
  //
  //  &-detail{
  //    & .col{
  //      position: relative;
  //      &:after{
  //        content: "";
  //        position: absolute;
  //        right: 5px;
  //        width: 18px;
  //        height: 18px;
  //        background: $icon-spnav-link $icon-setup-center ;
  //      }
  //
  //      a{
  //        display: inline-block;
  //        width: 100%;
  //      }
  //    }
  //  }
  //
  //  &-box{
  //    position: fixed;
  //    width: 100vw;
  //    height: 0;
  //    overflow-y: hidden;
  //    top: 67px;
  //    right: 0;
  //    z-index: 10;
  //    background-color: $base-color-bg-card;
  //    opacity: 0;
  //    transition: opacity 300ms;
  //    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  //
  //    @media (min-width: $media-md) {
  //      &{
  //        width: 700px;
  //        right: 50px;
  //
  //        .menu-category{
  //          pointer-events: none;
  //
  //          &:after{
  //            content: "";
  //          }
  //          &:hover{
  //            cursor: initial;
  //            opacity: 1;
  //          }
  //        }
  //        .menu-detail{
  //          display: block;
  //          pointer-events: initial;
  //
  //          & .col:after{
  //            background: none;
  //          }
  //
  //        }
  //      }
  //    }
  //
  //    & > .row{
  //      padding: 5px;
  //
  //    }
  //
  //  }
  //
  //}
  //
  //
  //#header-menu-checkbox:checked ~ .menu-box{
  //  display: block;
  //  height: auto;
  //  opacity: 1;
  //}
} // .l-renewal-content